import {
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import 'react-native-gesture-handler';
import RootNavigatorFlow from './navigation/RootNavigatorFlow';
import { Provider as PaperProvider } from 'react-native-paper';
import { darkTheme } from './config/theme';
import * as SplashScreen from 'expo-splash-screen';
import { store, persistor } from './src/store';
import { Provider } from 'react-redux';
import * as cookieRegistration from './src/cookieRegistration';
import * as recaptchaRegistration from './src/recaptchaRegistration';
import { initOneSignal, updateOneSignalTags } from './src/one-signal';
import {
  useFonts,
  Rubik_300Light,
  Rubik_400Regular,
  Rubik_500Medium,
  Rubik_600SemiBold,
  Rubik_700Bold,
} from '@expo-google-fonts/rubik';
import {
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
} from '@expo-google-fonts/roboto';
import AppLoading from 'expo-app-loading';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';
import { createURL } from 'expo-linking';
import { LinkingConfig } from './navigation/LinkingConfig';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import Toast from 'react-native-toast-message';
import { toastConfig } from './config/theme/toastConfig';
import { useTranslation } from 'react-i18next';
import { enableLegacyWebImplementation } from 'react-native-gesture-handler';
import BATAnnouncements from './src/components/announcements/BATAnnouncements';
import BATGeneralMessageReconsent from './src/components/BATGeneralMessageReconsent';
import moment from 'moment';
import redirects from './redirects.json';
import { AppPushGA } from './src/components/AppPushGA';
import { redirectToAdventCalendar } from './screens/AdventCalendar/utils';
import { OneSignalLogin } from './src/components/OneSignal';
import './styles/onesignal.module.css';
import { MakeUsersInstallPwaDialog } from './src/components/MakeUsersInstallPwaDialog';

export default function App() {
  const navRef = useNavigationContainerRef();
  const routeNameRef: any = useRef();

  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [month24AnonymizeDate, setMonth24AnonymizeDate] = useState<string | undefined>();
  const [currentRouteName, setCurrentRouteName] = useState<string | undefined>();

  const { t } = useTranslation();

  let [fontsLoaded] = useFonts({
    Rubik_300Light,
    Rubik_400Regular,
    Rubik_500Medium,
    Rubik_600SemiBold,
    Rubik_700Bold,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    IcoMoon: require('./assets/icons/icomoon.ttf'),
  });

  const daysUntilShowUserReconsent = 45;
  const showUserReconsent =
    !!month24AnonymizeDate &&
    moment(month24AnonymizeDate).startOf('day').diff(moment().startOf('day'), 'days') <=
      daysUntilShowUserReconsent;

  enableLegacyWebImplementation(true);

  useEffect(() => {
    initOneSignal();
    SplashScreen.preventAutoHideAsync().then();
  }, []);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  // deep linking
  const linking: LinkingOptions<{}> = {
    prefixes: [createURL('/')],
    config: LinkingConfig,
  };

  return (
    <Provider store={store}>
      <PaperProvider theme={darkTheme}>
        <PersistGate loading={null} persistor={persistor}>
          <BottomSheetModalProvider>
            <NavigationContainer
              ref={navRef}
              onReady={() => {
                setReady(true);

                const redirect = redirects.find(
                  ([oldPath]) => oldPath === window.location.pathname,
                );
                if (redirect) {
                  const [oldPath, newPath] = redirect;
                  if (oldPath != newPath) {
                    window.location.pathname = newPath;
                  }
                }
              }}
              theme={darkTheme}
              linking={linking}
              documentTitle={{
                formatter: (options) => options?.title ?? 'OneUp',
              }}
              onStateChange={() => {
                setLoggedIn(!!store.getState().auth.userData?.consumerId);
                setMonth24AnonymizeDate(store.getState().auth.userData?.month24AnonymizeDate);
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navRef.getCurrentRoute()?.name;

                if (previousRouteName !== currentRouteName) {
                  // Save the current route name for later comparison
                  routeNameRef.current = currentRouteName;

                  if (currentRouteName !== 'appStack') {
                    setCurrentRouteName(currentRouteName);

                    redirectToAdventCalendar();

                    const { token, consumerId } = store.getState().auth;
                    updateOneSignalTags(token, consumerId);
                  }
                }
              }}>
              {ready ? (
                <>
                  {loggedIn && showUserReconsent && <BATGeneralMessageReconsent />}
                  {loggedIn && <BATAnnouncements />}
                  {loggedIn && <OneSignalLogin />}
                  {loggedIn && <MakeUsersInstallPwaDialog currentRouteName={currentRouteName} />}
                  <RootNavigatorFlow />
                  {loggedIn && <AppPushGA route={navRef.getCurrentRoute()?.name} />}
                </>
              ) : null}
            </NavigationContainer>
            <Toast config={toastConfig} /> {/* ref={(ref) => Toast.setRef(ref)} */}
          </BottomSheetModalProvider>
        </PersistGate>
      </PaperProvider>
    </Provider>
  );
}

cookieRegistration.register();
recaptchaRegistration.register();
