import { OptionModel } from '../models/common';

const getOptionLabel = (option: OptionModel | null) => {
  return option ? option.label : '';
};

const getOptionValue = (option: OptionModel | null) => {
  return option?.value;
};

const isNull = (obj: any) => obj === null;

const formatLimitMessage = (limitDate) => {
  const formattedDate = new Date(limitDate).toLocaleDateString('ro-RO', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
};

const hexToRgba = (hexCode: string, opacity: number = 1.0): string => {
  if (hexCode.startsWith('#')) {
    hexCode = hexCode.slice(1);
  }

  const r = parseInt(hexCode.slice(0, 2), 16);
  const g = parseInt(hexCode.slice(2, 4), 16);
  const b = parseInt(hexCode.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const isMobileDevice = () => {
  return (
    navigator.userAgentData?.mobile ||
    /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  );
};

const isPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export {
  getOptionLabel,
  getOptionValue,
  isNull,
  formatLimitMessage,
  hexToRgba,
  isMobileDevice,
  isPwa,
};
