import React, { useEffect, useState } from 'react';
import { Image, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { sharedStyles } from '../../config/theme/shared.styles';
import colors from '../../config/theme/shared/colors';
import { isMobileDevice, isPwa } from '../utils';
import { useIsMobile } from '../hooks/useIsMobile';
import { RootState } from '../store';
import BATDialog from './common/BATDialog';
import BATText from './common/BATText';
import BATButtonNew from './common/BATButtonNew';
import BATIcon from './BATIcon';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

interface MakeUsersInstallPwaDialogProps {
  currentRouteName?: string;
}

export const MakeUsersInstallPwaDialog = ({ currentRouteName }: MakeUsersInstallPwaDialogProps) => {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const [showDialog, setShowDialog] = useState(false);
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  const { t } = useTranslation();
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const { isMobile } = useIsMobile();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      setBeforeInstallPromptEvent(event as BeforeInstallPromptEvent);
    });
  }, []);

  useEffect(() => {
    const pwaDialogLastDisplayTimestamp = window.localStorage.getItem(
      'pwaDialogLastDisplayTimestamp',
    );

    if (pwaDialogLastDisplayTimestamp === null) {
      window.localStorage.setItem(
        'pwaDialogLastDisplayTimestamp',
        (moment().unix() - 7 * 24 * 3600).toString(),
      );
    } else {
      const shouldDisplay = moment().unix() > +pwaDialogLastDisplayTimestamp + 7 * 24 * 3600;
      const mustDisplay = moment().unix() > +pwaDialogLastDisplayTimestamp + 10 * 24 * 3600;
      const readyToDisplay = (shouldDisplay && beforeInstallPromptEvent !== null) || mustDisplay;

      if (userData && userData.ageVerified && isMobileDevice() && !isPwa() && readyToDisplay) {
        setTimeout(() => {
          setShowDialog(true);
          navigation.setParams({ pwa: beforeInstallPromptEvent !== null ? '1' : '2' });
        }, 5000);

        window.localStorage.setItem('pwaDialogLastDisplayTimestamp', moment().unix().toString());
      }
    }
  }, [userData?.consumerId, userData?.ageVerified, currentRouteName]);

  const handleInstallNow = () => {
    if (!beforeInstallPromptEvent) {
      return;
    }

    setShowDialog(false);
    navigation.setParams({ pwa: undefined });
    beforeInstallPromptEvent.prompt();
  };

  const body =
    beforeInstallPromptEvent !== null ? (
      <View style={sharedStyles.alignItemsCenter}>
        <View
          style={[
            sharedStyles.alignItemsCenter,
            sharedStyles.justifyContentCenter,
            sharedStyles.marginBottomTriple,
            sharedStyles.padding,
            {
              borderColor: colors.card_border_lighter,
              borderRadius: '50%',
              borderWidth: 1,
            },
          ]}>
          <BATIcon name="device-aod" color={colors.grey_title} size={62} />
        </View>

        <BATText
          type="heading-3"
          color={colors.grey_title}
          customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottom]}>
          {t('PWA_DIALOG_HEADING')}
        </BATText>

        <BATText customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottomTriple]}>
          {t('PWA_DIALOG_TEXT_PWA')}
        </BATText>
      </View>
    ) : (
      <View>
        <BATText
          type="heading-3"
          color={colors.grey_title}
          customStyle={[sharedStyles.marginBottom, sharedStyles.marginTopDouble]}>
          {t('PWA_DIALOG_HEADING')}
        </BATText>

        <BATText customStyle={[sharedStyles.marginBottomTriple]}>
          <Trans
            i18nKey="PWA_DIALOG_TEXT_NO_PWA"
            components={{
              Link: (
                <Text
                  style={{ textDecorationLine: 'underline', color: colors.violet }}
                  onPress={() => {
                    setShowDialog(false);
                    navigation.setParams({ pwa: undefined });
                    linkTo('/cum-instalez-aplicatia');
                  }}
                />
              ),
            }}
          />
        </BATText>

        <View style={sharedStyles.marginBottomQuadra}>
          <InstructionLine number={1}>
            <BATText type="heading-6" color={colors.grey_title}>
              Apasă pe iconița
            </BATText>

            <BATIcon
              name="ios-share"
              color={colors.grey_title}
              size={32}
              style={sharedStyles.marginHorizontalHalf}
            />

            <BATText type="heading-6" color={colors.grey_title}>
              sau
            </BATText>

            <BATIcon
              name="navigation-more-vertical"
              color={colors.grey_title}
              size={32}
              style={sharedStyles.marginHorizontalHalf}
            />

            <BATText type="heading-6" color={colors.grey_title}>
              din browser.
            </BATText>
          </InstructionLine>

          <InstructionLine number={2}>
            <BATText type="heading-6" color={colors.grey_title}>
              Scrollează și selectează “Add to Home Screen”.
            </BATText>
          </InstructionLine>

          <InstructionLine number={3}>
            <BATText type="heading-6" color={colors.grey_title}>
              Apasă pe “Add” sau “Install”.
            </BATText>
          </InstructionLine>

          <InstructionLine number={4}>
            <BATText type="heading-6" color={colors.grey_title}>
              Folosește
            </BATText>

            <View
              style={[
                sharedStyles.marginHorizontal,
                { backgroundColor: colors.white, borderRadius: 4, padding: 1 },
              ]}>
              <Image
                source={{ uri: require('../../assets/icon.png') }}
                style={{ height: 26, width: 28 }}
              />
            </View>

            <BATText type="heading-6" color={colors.grey_title}>
              direct din Home Screen.
            </BATText>
          </InstructionLine>
        </View>
      </View>
    );

  const footer =
    beforeInstallPromptEvent !== null ? (
      <View style={[sharedStyles.widthFull, { gap: 16, marginBottom: isMobile ? 32 : 24 }]}>
        <BATButtonNew variant="contained" handleSubmitButton={handleInstallNow}>
          {t('INSTALL_APP')}
        </BATButtonNew>

        <BATButtonNew
          variant="text"
          handleSubmitButton={() => {
            setShowDialog(false);
            navigation.setParams({ pwa: undefined });
          }}>
          {t('LATER')}
        </BATButtonNew>
      </View>
    ) : (
      <></>
    );

  return (
    <BATDialog
      visible={showDialog}
      showAsDrawerOnMobile
      body={body}
      footer={footer}
      customStyles={{
        borderBottomLeftRadius: isMobile ? 0 : undefined,
        borderBottomRightRadius: isMobile ? 0 : undefined,
        width: '100%',
        marginHorizontal: 0,
      }}
      bodyStyles={{ paddingHorizontal: 16, paddingVertical: 0 }}
      customActionsStyles={{
        marginHorizontal: isMobile ? 16 : 24,
        padding: 0,
      }}
      onDismiss={() => {
        setShowDialog(false);
        navigation.setParams({ pwa: undefined });
      }}
    />
  );
};

const InstructionLine = ({ number, children }: { number: number; children: React.ReactNode }) => (
  <View
    style={[
      sharedStyles.alignItemsCenter,
      sharedStyles.displayFlex,
      sharedStyles.marginBottomDouble,
    ]}>
    <View
      style={[
        sharedStyles.alignItemsCenter,
        sharedStyles.justifyContentCenter,
        sharedStyles.marginRight,
        {
          backgroundColor: colors.card_border_lighter,
          borderRadius: 16,
          height: 32,
          width: 32,
        },
      ]}>
      <BATText type="heading-6" color={colors.grey_title}>
        {number}
      </BATText>
    </View>

    {children}
  </View>
);
