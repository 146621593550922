import { createSlice } from '@reduxjs/toolkit';

import { ProductSearch, ProductsState } from '../models';

const getInitialState = (): ProductsState => ({ productsSearch: [] });

const slice = createSlice({
  name: 'products',
  initialState: getInitialState(),
  reducers: {
    setProductsSearch: (state, action: { payload: ProductSearch[] }) => {
      state.productsSearch = action.payload;
    },
  },
});

export const { setProductsSearch } = slice.actions;

export default slice.reducer;
