import * as React from 'react';
import { Image, useWindowDimensions, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { RootState } from '../src/store';
import { PrizeFlow } from '../screens/Prizes/PrizeStack';

import { ScreenNames } from '../screens/ScreenNames';
import ProfileFlow from '../screens/Profile/ProfileFlow';
import ArticleFlow from '../screens/Articles/ArticleFlow';

import SurveyFlow from '../screens/Survey/SurveyFlow';
import { memo, useEffect, useState } from 'react';
import { useFetchUserDataMutation } from '../services/auth';
import { ProductsStack } from '../screens/Products/ProductsStack';
import colors from '../config/theme/shared/colors';
import { CampaignsListStack } from '../screens/Campaigns/CampaignsListStack';
import { updateScreenType } from '../src/slices/appSettingsSlice';
import { anonymousLazyScreen } from '../src/components/Lazy/anonymousLazyScreen';
import { protectedLazyScreen } from '../src/components/Lazy/protectedLazyScreen';
import AuthFlow from '../screens/Authentication/AuthFlow';
import AgeVerificationFlow from './AgeVerificationFlow';
import '../services/index';
import { useGetReCaptchaValidationStatusMutation } from '../services/reCaptcha';
import { useNavigation } from '@react-navigation/native';

const InsertPackCode = protectedLazyScreen(() => import('../screens/Feed/InsertPackCode'));
const InsertBonusCode = protectedLazyScreen(() => import('../screens/Feed/InsertBonusCode'));
const InsertBonusCodeSuccess = protectedLazyScreen(
  () => import('../screens/Feed/InsertBonusCodeSuccess'),
);
const NotificationList = protectedLazyScreen(() => import('../screens/Feed/NotificationList'));
const QuizzesList = protectedLazyScreen(() => import('../screens/Wallet/QuizzesList'));
const StoriesPage = protectedLazyScreen(() => import('../screens/Stories/StoriesPage'));
const OnBoarding = protectedLazyScreen(() => import('../screens/Onboarding/Onboarding'));
const PDFs = protectedLazyScreen(() => import('../screens/PDFs'));
const WinPrizesPdfs = protectedLazyScreen(() => import('../screens/WinPrizesPdfs'));
const MainNavigator = protectedLazyScreen(() => import('../screens/MainNavigator'));
const AllSearchResults = protectedLazyScreen(() => import('../screens/AllSearchResults'));
const HowActivatePushNotifications = protectedLazyScreen(
  () => import('../screens/HowActivatePushNotifications/HowActivatePushNotifications'),
);
const HowInstallApp = protectedLazyScreen(() => import('../screens/HowInstallApp/HowInstallApp'));
const AdventCalendar = protectedLazyScreen(
  () => import('../screens/AdventCalendar/AdventCalendar'),
);
const CustomArticleThr = protectedLazyScreen(() => import('../screens/Articles/CustomArticleThr'));

const Viewer = anonymousLazyScreen(() => import('../screens/Viewer'));

const Stack = createStackNavigator();

export default memo(function RootNavigatorFlow() {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state?.auth?.userData);

  const [fetchUserData] = useFetchUserDataMutation();
  const [getReCaptchaValidationStatus] = useGetReCaptchaValidationStatusMutation();

  const dispatch = useDispatch();
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  const { width } = useWindowDimensions();

  const inactivityTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const navigation = useNavigation();

  const startInactivityTimeout = (redirectPath: string | null) => {
    if (window.location.pathname !== `/introducere`) {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      inactivityTimeout.current = setTimeout(() => {
        navigation.navigate(ScreenNames.onBoarding, { redirectTo: redirectPath });
      }, 120000);
    }
  };

  const getCurrentRoutePath = () => {
    const href = window.location.href;
    const hrefWithoutOrigin = href.replace(window.location.origin, '');

    return hrefWithoutOrigin;
  };

  useEffect(() => {
    if (userData && !userData.hideOnboarding) {
      const initialPath = getCurrentRoutePath();
      startInactivityTimeout(initialPath);

      const unsubscribe = navigation.addListener('state', () => {
        const currentPath = getCurrentRoutePath();
        startInactivityTimeout(currentPath);
      });

      return () => {
        if (inactivityTimeout.current) {
          clearTimeout(inactivityTimeout.current);
        }
        unsubscribe();
      };
    } else return undefined;
  }, [userData, navigation]);

  useEffect(() => {
    dispatch(updateScreenType(width));
  }, [width, dispatch]);

  useEffect(() => {
    getReCaptchaValidationStatus({});

    if (userData) {
      fetchUserData({ consumerId: userData.consumerId });
    }

    setTimeout(() => {
      setShowLoadingAnimation(false);
    }, 700);
  }, []);

  const stackNavigator = () => (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={ScreenNames.userStack} component={AuthFlow} />
      <Stack.Screen name={ScreenNames.ageVerificationStack} component={AgeVerificationFlow} />
      <Stack.Screen name={ScreenNames.appStack} component={MainNavigator} />
      <Stack.Screen
        name={ScreenNames.onBoarding}
        options={{ title: t('ONBOARDING_TITLE') }}
        component={OnBoarding}
      />
      <Stack.Screen
        name={ScreenNames.insertPackCode}
        key={ScreenNames.insertPackCode}
        options={{ title: t('INSERT_PACK_CODE_HERE') }}
        component={InsertPackCode}
      />
      <Stack.Screen
        name={ScreenNames.insertBonusCode}
        key={ScreenNames.insertBonusCode}
        options={{ title: t('INSERT_BONUS_CODE_TITLE') }}
        component={InsertBonusCode}
      />
      <Stack.Screen
        name={ScreenNames.insertBonusCodeSuccess}
        key={ScreenNames.insertBonusCodeSuccess}
        options={{ title: t('INSERT_BONUS_CODE_SUCCESS_TITLE') }}
        component={InsertBonusCodeSuccess}
      />
      <Stack.Screen
        name={ScreenNames.notification}
        key={ScreenNames.notification}
        options={{ title: t('NOTIFICATION') }}
        component={NotificationList}
      />
      <Stack.Screen
        name={ScreenNames.profile}
        key={ScreenNames.profile}
        options={{ title: t('PROFILE_LABEL') }}
        component={ProfileFlow}
      />
      <Stack.Screen
        name={ScreenNames.articles}
        options={{ title: t('ARTICLE') }}
        component={ArticleFlow}
      />
      <Stack.Screen name={'survey'} options={{ title: t('SURVEY_TITLE') }} component={SurveyFlow} />
      <Stack.Screen
        name={ScreenNames.products}
        options={{ title: t('PRODUCTS_LABEL') }}
        component={ProductsStack}
      />
      <Stack.Screen
        name={ScreenNames.campaignsList}
        options={{ title: t('CAMPAIGNS_TITLE') }}
        component={CampaignsListStack}
      />
      <Stack.Screen name={'prize'} options={{ title: t('PRIZES_TITLE') }} component={PrizeFlow} />
      <Stack.Screen
        name={ScreenNames.quizzesList}
        options={{ title: t('QUIZZES_LIST'), headerShown: false }}
        component={QuizzesList}
      />
      <Stack.Screen
        name={ScreenNames.stories}
        options={{ title: t('STORIES') }}
        component={StoriesPage}
      />
      <Stack.Screen name={ScreenNames.pdfs} options={{ title: t('PDFS') }} component={PDFs} />
      <Stack.Screen
        name={ScreenNames.winPrizesPdfs}
        options={{ title: t('WINNERS_OF_PRIZES') }}
        component={WinPrizesPdfs}
      />
      <Stack.Screen
        name={ScreenNames.allSearchResults}
        key={ScreenNames.allSearchResults}
        options={{ title: t('BAT_SEARCH_ALL_RESULTS_PAGE_TITLE') }}
        component={AllSearchResults}
      />
      <Stack.Screen
        name={ScreenNames.howActivatePushNotifications}
        key={ScreenNames.howActivatePushNotifications}
        options={{ title: t('ONE_SIGNAL_HOW_ACTIVATE_PUSH_NOTIFICATIONS_TITLE') }}
        component={HowActivatePushNotifications}
      />
      <Stack.Screen
        name={ScreenNames.howInstallApp}
        key={ScreenNames.howInstallApp}
        options={{ title: t('HOW_INSTALL_APP') }}
        component={HowInstallApp}
      />
      <Stack.Screen name={ScreenNames.viewer} component={Viewer} />
      <Stack.Screen
        name={ScreenNames.adventCalendar}
        key={ScreenNames.adventCalendar}
        options={{ title: t('ADVENT_CALENDAR_PAGE_TITLE') }}
        component={AdventCalendar}
      />
      <Stack.Screen
        name="custom-article-thr"
        key="custom-article-thr"
        options={{ title: 'glo way better stories' }}
        component={CustomArticleThr}
      />
    </Stack.Navigator>
  );

  const loadingAnimationTemplate = () => (
    <View
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.background,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Image source={{ uri: require('../assets/icon.png') }} style={{ width: 100, height: 94 }} />
    </View>
  );

  return showLoadingAnimation ? loadingAnimationTemplate() : stackNavigator();
});
